import React from 'react'
import Testimonial from './inc/Testimonial'
const About = () => {
  return (
    <div>
  <div className="banner about" id="home" style={{backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657704090/Lincoln Globalcapitabanner1.png")'}}>
    {/* hero-area Start */}
    <div className="hero-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-8  wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="banner-content">
              <h2 className="title text-dark">About Us</h2>
              <ul className="breadcrumb">
                <li>
                  <a href="{{url('/')}}">Home</a> <i className="flaticon-airplane49" />
                </li>
                <li>
                  About Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  {/*  History-section Start */}
  <section className="history-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            
            {/* <h4 className="lasthead">Understanding our strategies</h4> */}
            <h2 className="title text-center text-dark">Lincoln Global</h2>
            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            Our Firm Takes A Professional Approach To Diversify Portfolio Management Which
            Features Discipline, A Focus On Quality And Conservative Risk Management. Our Creative Approach To Portfolio Management Balances Superior Long-Term Returns With Minimal Risk Exposure.

            </p>

            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            The depth and experience of our investment team allows us to be proactive in managing client portfolios. Discipline, attentiveness and patience do the rest. We believe that superior investment performance is achieved through a skillful balance of three core attributes:
Knowledge, Experience and Adaptability.

            </p>

            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
              <strong>STRATEGY THAT FOCUSES ON BUILDING</strong> <br />
              We believe that systematic mistakes by investors combined with structural and temporal features of markets create security mispricings, extensive empirical evidence supports this belief. Our disciplined and systematic process exploits these mispricings, seeking to build high risk-adjusted return portfolios for our clients.
            </p>

            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            Lincoln Global also recognizes that markets are adaptive, that investment strategies go in and out of favor, and risk or reward relationships change over time. We acknowledge the importance of recognizing shifts in the market environment and knowing the value of different pieces of information at different points in time across global markets.
We also know that, at times, even the most sophisticated stock selection attributes will converge towards temporary macro risk trends.
            </p>

            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
              <strong>Lincoln Global PORTFOLIO</strong> <br />
              Lincoln Global Portfolio is a tool for investment in portfolios of digital assets. Lincoln Global offers portfolios with various indicators of risk/return ratio. Any user with or without experience could select a portfolio.
            </p>
            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
              <strong>Lincoln Global FIXED INCOME</strong> <br />
              Lincoln Global fixed income portfolio is a tool for establishing a steady flow of a fixed income. FIP is a portfolio formed from all investment products of Lincoln Global DeFi Platform. Portfolio consists of Staking, Mining and Invest Portfolio. The user gets the opportunity to receive the maximally high income with minimal risks. We are teetering this portfolio not only on falling within cryptocurrencies, but also on products.
            </p>
           <p>
            </p></div>
        </div>
      </div>
    </div>
  </section>
  {/* Team Section Start */}
  <div className="team-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <h4 className="lasthead">Meet our most valued</h4>
            <h2 className="title">Expert Team Members</h2>
            <p className="text">
              With Trust and transparency as our foundation, we want to help individuals make proper use of their money without stress.
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="team  owl-carousel owl-theme">
            <div className="team-single item wow fadeInUp" data-wow-duration="0.2s" data-wow-delay="0.2s">
              <div className="thumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1651834563/review-author-6.jpg" alt="user" />
              </div>
              <div className="content">
                <h5 className="text"><a href="#">Alex Love</a></h5>
                <p className="text">Manager</p>
              </div>
            </div>
            <div className="team-single item wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <div className="thumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657825108/review-author-4.jpg" alt="user" />
              </div>
              <div className="content">
                <h5 className="text"><a href="#">Steven Mann</a></h5>
                <p className="text">Devoloper</p>
              </div>
            </div>
            <div className="team-single item wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="thumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1651834563/review-author-7.jpg" alt="user" />
              </div>
              <div className="content">
                <h5 className="text"><a href="#">Robert Knight</a></h5>
                <p className="text">Founder </p>
              </div>
            </div>
            <div className="team-single item wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="thumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1651834563/review-author-3.jpg" alt="user" />
              </div>
              <div className="content">
                <h5 className="text"><a href="#">Kate Anderson</a></h5>
                <p className="text">Data Analyst </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Testimonial/>
</div>


  )
}

export default About
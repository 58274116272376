import React from 'react'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

const Widgets = () => {
    

    return (
        <AdvancedRealTimeChart symbol='BITSTAMP:BTCUSD' theme="light" width="100%">

        </AdvancedRealTimeChart>
    )
}

export default Widgets;
import React from 'react'

const Testimonial = () => {
    

    return (
       <div className="testomonial wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s" id="testimonial">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="testo-box owl-carousel owl-theme">
          <div className="single item">
            <div className="person">
              <div className="tumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655800608/team-4.jpg" alt="user" />
              </div>
              <h2 className="name">Myron</h2>
              <h5>investor</h5>
            </div>
            <div className="content">
              <h4 className="lasthead">
                “Very helpful excellent service always answering all my questions And the most
                important thing was that wat they offer me is wat it was I highly recommended thanks
                for all the help keep up the good work”
              </h4>
            </div>
          </div>
          <div className="single item">
            <div className="person">
              <div className="tumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655180250/Serkan.137d4518.jpg" alt="user" />
              </div>
              <h2 className="name">Alfredo</h2>
              <h5>user</h5>
            </div>
            <div className="content">
              <h4 className="lasthead">
                I love the performance of your traders, they are truly professionals. I love this platform.
              </h4>
            </div>
          </div>
          <div className="single item">
            <div className="person">
              <div className="tumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655180543/Samuele.ed055e36.jpg" alt="user" />
              </div>
              <h2 className="name">Ellis</h2>
              <h5>User</h5>
            </div>
            <div className="content">
              <h4 className="lasthead">
                lincoln Global offers many automatic strategies suitable for any type of market, bullish or bearish.
              </h4>
            </div>
          </div>
          <div className="single item">
            <div className="person">
              <div className="tumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655180290/Michael.01882b25.jpg" alt="user" />
              </div>
              <h2 className="name">Andrew</h2>
              <h5>User</h5>
            </div>
            <div className="content">
              <h4 className="lasthead">
                The most impressive part of this company is the ability to use their arbitrage trading to make a profit
              </h4>
            </div>
          </div>
          <div className="single item">
            <div className="person">
              <div className="tumb">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655180570/Simone.8d16dde8.png" alt="user" />
              </div>
              <h2 className="name"> Castillo</h2>
              <h5>Investor</h5>
            </div>
            <div className="content">
              <h4 className="lasthead">
                I love your support system always available to help anytime. I love you guys
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className='card'>
          <img src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1697475418/84783fdc8049497cba4e4cb8f04d9b4al3SAhbSwGSEC4DB0-0.jpg'/>
        </div>
      </div>

    </div>
  </div>
</div>

    )
}

export default Testimonial;
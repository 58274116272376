import React from 'react'

const Mission = () => {
  return (
    <div className="mission" id="mission" style={{marginTop: '-310px', backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868140/mission-bg.png")'}}>
  <div className="container">
    <div className="row align-items-center justify-content-between">
      <div className="col-md-7">
        <div className="section-head">
          <h3 className="subtitle wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">Our Mission</h3>
          <h2 className="title wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">Help Individuals and Organizations Wherever to reach their financial goals.</h2>
          <p className="text wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">
            Deposit, earn profits, make payments, manage and track your balances without skipping a beat
          </p>
          <ul className="list">
            <li className="list-item wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
              <p className="text">Secure payments</p>
            </li>
            <li className="list-item wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
              <p className="text">Total control over investments</p>
            </li>
            <li className="list-item wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
              <p className="text">Reminders</p>
            </li>
            <li className="list-item wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
              <p className="text">Steady Income  </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-5 d-md-block">
        <div className="pic wow fadeInRight" data-wow-duration="0.4s" data-wow-delay="0.4s">
          <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659867702/laptop-man.png" alt="user" className="laptop-man" />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Mission
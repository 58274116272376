import React, {Fragment, useEffect, useState} from 'react'
import apiClient from "../../http-common";
import { useQuery } from "react-query";

 const CoinList = () => {
    
    const [coins, setCoins] = useState(null);

    const { isLoading: isLoadingCOins, refetch: loadCoins } = useQuery(
        "coin-rates",
        async () => {
          return await apiClient.get("/api/get-coin-rate");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            setCoins(res.data.coins);
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(()=>{
        loadCoins();
      }, [])
      const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
      const availableCoins = ['BTC', 'LTC', 'DOGE', 'DASH', 'BNB'];

    return (
        <div>
        {isLoadingCOins ? <span>loading...</span> :
    
<div className="compare" id="currency" style={{marginTop: '-80px', backgroundColor: "#fafafc"}}>
  {coins !== null &&
    <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="coin-box owl-carousel owl-theme d-flex justify-content-center">
            {Object.keys(coins).map(coin =>(
                availableCoins.includes(coins[coin].symbol) &&
                <Fragment key={coins[coin].symbol}>

                    <div className="compare-box item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                        <div className="single bg-white">
                        <div className="icon">
                            <img src={
                                coin === 'TCN' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451074/242cd1653295679.png' ||
                                coin === 'BTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/btc-sw.png'||
                                coin === 'LTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450859/ltc-sw.png'||
                                coin === 'BCH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/bch-sw.png'||
                                coin === 'DOGE' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/doge-sw2.png'||
                                coin === 'DASH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451267/ba8ea1653295812.png'||
                                coin === 'USDTTRC20' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554232/usdttrc20.png'||
                                coin === 'USDTERC20' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554186/usdterc20.png'||
                                coin === 'TRX' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554250/trx.png'||
                                coin === 'XMR' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554258/xmr.png'||
                                coin === 'BNB' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451323/ac2f71653295852.png'
                            } alt="coin" className="coin-icon" />
                            <p className="text"><span>{coins[coin].symbol}</span></p>
                        </div>
                        <h4 className="text-center mt-4">${coins[coin].price}</h4>
                        </div>
                    </div>
                    
                </Fragment>
            ))}
        </div>
      </div>
    </div>
  </div>
  }
</div>    
    }
</div>
    )
}

export default CoinList

import React from 'react'

const Counter = () => {
    

    return (
<div className="counter" style={{backgroundColor: '#03490d', backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1693576839/linclo-gglo.png")'}}>
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="0.2s" data-wow-delay="0.2s">
        <div className="page-counter">
          <div className="counter-item">
            <h2 className="title"><span className="count-num">36</span>M</h2>
            <p className="text">Registered users</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="page-counter">
          <div className="counter-item">
            <h2 className="title"><span className="count-num">240</span></h2>
            <p className="text">Countries supported</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
        <div className="page-counter">
          <div className="counter-item">
            <h2 className="title">$<span className="count-num">10</span>M</h2>
            <p className="text">Withdrawn each month</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
        <div className="page-counter four">
          <div className="counter-item">
            <h2 className="title"><span className="count-num">18</span>k</h2>
            <p className="text">Active investors daily</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default Counter;
